<template>
  <div class="inner-section">
    <bread-cumb />
    <body-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGarden.salary_sheet_monthly_staff') + ' ' + $t('globalTrans.report') }}</h4>
      </template>
      <template v-slot:headerAction>
        <a href="javascript:" class="btn-add" @click="pdfExport"><i class="ri-printer-line"></i> {{ $t('globalTrans.print') }}</a>
      </template>
      <template v-slot:body>
          <b-overlay :show="loading">
            <b-row>
              <b-col lg="12" xs="12" md="12">
                  <b-row>
                      <b-col lg="12" xs="12" md="12">
                        <list-report-head :base-url="baseUrl" uri="/configuration/report-heading/detail" :org-id="5">
                          <template v-slot:projectNameSlot>
                          </template>
                          {{ $i18n.locale === 'en' ? 'SALARY SHEET (Staff) REPORT' : 'বেতন শীট (কর্মচারী) প্রতিবেদন' }}
                        </list-report-head>
                      </b-col>
                  </b-row>
                  <b-row>
                    <b-col v-if="report" style="font-size: 14px" sm="6">
                      <div>
                        <span>{{ $t('teaGarden.tea_garden') }}: </span>
                        <span><b>{{ getGardenName(report.garden_id) }}</b></span>
                      </div>
                    </b-col>
                  </b-row>
              </b-col>
            </b-row>
            <b-row v-if="detailsData.length">
              <b-col lg="12" xs="12" md="12" class="tableFixHead">
                  <b-table-simple bordered style="font-size: 13px">
                      <b-thead class="bg-light tableFixHead2">
                          <b-tr>
                            <b-th class="align-middle text-center" :colspan="positiveHeads.length + 11">
                              {{ currentLocale == 'en' ? 'For the month of ' + getMonthName(report.month) + ' ' + report.year : $n(report.year, { useGrouping: false }) + ' সালের ' + getMonthName(report.month) + ' মাসের জন্য'}}
                            </b-th>
                          </b-tr>
                          <b-tr class="text-center align-middle">
                            <b-th width="5%">{{ $t('globalTrans.sl_no') }}</b-th>
                            <b-th>{{ $t('globalTrans.name') }}</b-th>
                            <b-th>{{ $t('globalTrans.designation') }}</b-th>
                            <b-th v-for="(item, index) in positiveHeads" :key="index">{{ getHeadName(item.weg_head_id) }}</b-th>
                            <b-th>{{ $t('teaGarden.total_salary_and_allowance') }}</b-th>
                            <b-th>{{ $t('teaGarden.pf_office') }}</b-th>
                            <b-th>{{ $t('teaGarden.pf_self') }}</b-th>
                            <b-th>{{ $t('teaGarden.income_tax') }}</b-th>
                            <b-th>{{ $t('teaGarden.union_tax') }}</b-th>
                            <b-th>{{ $t('teaGarden.revenue') }}</b-th>
                            <b-th>{{ $t('teaGarden.total_deduction') }}</b-th>
                            <b-th>{{ $t('teaGarden.total_payable') }}</b-th>
                          </b-tr>
                      </b-thead>
                      <b-tbody>
                          <b-tr v-for="(item, index) in detailsData" :key="index" class="text-center align-middle">
                            <b-td>{{ $n(index + 1 ) }} </b-td>
                            <b-td class="text-left">{{ currentLocale === 'en' ? item.employee.worker_name_en : item.employee.worker_name_bn }}</b-td>
                            <b-td class="text-left">{{ getDesignation(item.designation_id) }}</b-td>
                            <b-td v-for="(itm, indx) in item.details.filter(item => parseInt(item.add_deduct) === 1)" :key="indx">
                                {{ $n(itm.amount, { minimumFractionDigits: 2 }) }}
                            </b-td>
                            <b-td>{{ $n(item.total_salary_and_allowance, { minimumFractionDigits: 2 }) }}</b-td>
                            <b-td>{{ $n(item.pf_office, { minimumFractionDigits: 2 }) }}</b-td>
                            <b-td>{{ $n(item.pf_self, { minimumFractionDigits: 2 }) }}</b-td>
                            <b-td>{{ $n(item.income_tax, { minimumFractionDigits: 2 }) }}</b-td>
                            <b-td>{{ $n(item.union_subscription, { minimumFractionDigits: 2 }) }}</b-td>
                            <b-td>{{ $n(item.revenue, { minimumFractionDigits: 2 }) }}</b-td>
                            <b-td>{{ $n(item.total_deduction, { minimumFractionDigits: 2 }) }}</b-td>
                            <b-td>{{ $n(item.total_payable, { minimumFractionDigits: 2 }) }}</b-td>
                          </b-tr>
                      </b-tbody>
                  </b-table-simple>
              </b-col>
            </b-row>
          </b-overlay>
      </template>
    </body-card>
  </div>
</template>
<script>
import BreadCumb from '@/components/BreadCumb.vue'
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { staffSalaryMonthlyView } from '../../../api/routes'
import ListReportHead from '@/components/custom/TeaGardenReportHead.vue'

export default {
    name: 'Form',
    props: [],
    components: { ListReportHead, BreadCumb },
    data () {
      return {
        baseUrl: teaGardenServiceBaseUrl,
        loading: false,
        report: null,
        detailsData: [],
        positiveHeads: [],
        garden_id_no: ''
      }
    },
    computed: {
      gdnWagesHeadList () {
        return this.$store.state.TeaGardenService.commonObj.gdnWagesHeadList.filter(el => el.status === 1 && JSON.parse(el.employee_types).includes(1))
      },
      masterTeaGardenGenInfoList () {
          return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(el => el.status === 1)
      },
      currentLocale () {
        return this.$i18n.locale
      }
    },
    created () {
      window.scrollTo(0, 0)
      if (this.$route.query.id) {
        this.loading = true
        const tmp = this.getStoreListData(this.$route.query.id)
        this.report = tmp
        if (Object.values(tmp).length) {
          this.getDetailsData(tmp)
        }
      }
    },
    methods: {
      getDesignation (id) {
          const designation = this.$store.state.TeaGardenService.commonObj.masterDesignationList.find(item => item.value === id)
          if (designation && this.$i18n.locale === 'bn') {
              return designation.text_bn
          } else if (designation && this.$i18n.locale === 'en') {
              return designation.text_en
          }
      },
      getHeadName (headId) {
        const headName = this.gdnWagesHeadList.find(item => parseInt(item.value) === headId)
        if (typeof headName !== 'undefined') {
          return this.currentLocale === 'en' ? headName.text_en : headName.text_bn
        }
        return ''
      },
      getMonthName (Id) {
        const Obj = this.$store.state.commonObj.monthList.find(el => el.value === parseInt(Id))
        if (typeof Obj !== 'undefined') {
          return this.currentLocale === 'en' ? Obj.text_en : Obj.text_bn
        }
        return ''
      },
      getGardenName (Id) {
        const Obj = this.masterTeaGardenGenInfoList.find(el => el.value === parseInt(Id))
        if (typeof Obj !== 'undefined') {
          this.garden_id_no = Obj.garden_id_no
          return this.currentLocale === 'en' ? Obj.text_en : Obj.text_bn
        }
        return ''
      },
      BangNum (n) {
         if (this.$i18n.locale === 'bn') {
            return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
         }
         return n
      },
      async getDetailsData (data) {
        const myObj = {
          year: data.year,
          month: data.month,
          garden_id: data.garden_id
        }
        const resultData = await RestApi.getData(teaGardenServiceBaseUrl, staffSalaryMonthlyView, myObj)
        if (resultData.success) {
          this.detailsData = resultData.data
          this.positiveHeads = this.detailsData[0].details.filter(item => parseInt(item.add_deduct) === 1)
          this.loading = false
        } else {
          this.detailsData = []
          this.positiveHeads = []
          this.loading = false
        }
      },
      async pdfExport () {
        this.loading = true
        const params = {
          year: this.report.year,
          month: this.report.month,
          garden_id: this.report.garden_id,
          request_type: 'pdf',
          local: this.$i18n.locale,
          org_id: 5,
          garden_id_no: this.garden_id_no
        }

        const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, staffSalaryMonthlyView, params)
        var blob = new Blob([result], {
          type: 'application/pdf'
        })
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.loading = false
      },
      getStoreListData (Id) {
        const tmpData = this.$store.state.list.find(item => item.id === parseInt(Id))
        return JSON.parse(JSON.stringify(tmpData))
      }
    }
}
</script>
<style scoped>
  .table-bordered thead th, .table-bordered thead td {
      border-bottom-width: 1px !important;
      border-color: #333 !important;
  }
  .table-bordered tbody th, .table-bordered tbody td {
      border-bottom-width: 1px !important;
      border-color: #333 !important;
  }
  .table th, .table td {
      padding: 0.25rem !important;
  }

.tableFixHead { overflow: auto; height: 500px; }
.tableFixHead .tableFixHead2 { position: sticky; top: 0; z-index: 1;}
.font-bn .table-wrapper table thead tr th {
  font-size: 14px !important;
}
.font-bn .table-wrapper table tbody tr td {
  font-size: 14px !important;
}
</style>
